import React from 'react'
import Veprimtarite from '../activity/Veprimtarite'

const VeprimtaritePage = () => {
  return (
    <>
      <Veprimtarite />
    </>
  )
}

export default VeprimtaritePage