import React from 'react'
import Contact from '../contact/Contact'

const ContactPage = () => {
  return (
    <>
      <Contact />
    </>
  )
}

export default ContactPage