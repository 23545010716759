import yourImage1 from "../assets/1.jpg"
import yourImage2 from "../assets/2.jpg"
import yourImage3 from "../assets/Parku_Germia.jfif"
import yourImage4 from "../assets/3.jpg"
import yourImage5 from "../assets/4.jpg"
import yourImage6 from "../assets/5.jpg"

const data = [
    {
      id:1,
      image: yourImage1,
      backgroundColor: '#003249',
      textColor: '#FFF6E7',
      buttonColor: '#F18934',
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owne",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."  
    }
    },
    {
      id:2,
      image: yourImage2,
      backgroundColor: '#FFF6E7',
      textColor: '#003249',
      buttonColor: '#F18934',
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .Your trzusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality. To help you succeed. Whether you're a small business owner looking to free up some time or a large corporation seeking to streamline your processes and i",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."  
    }
    },
    {
      id:3,
      image: yourImage3,
      backgroundColor: '#6ADB84',
      textColor: '#003249',
      buttonColor: '#F18934',
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."  
    }
    },
    {
      id:4,
      image: yourImage4,
      backgroundColor: '#6ADB84',
      textColor: '#003249',
      buttonColor: '#F18934',
      margin: "-125px",
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."
      }
    },
    {
      id:5,
      image: yourImage5,
      backgroundColor: '#006646',
      textColor: '#6ADB84',
      buttonColor: '#F18934',
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."
      }
    },
    {
      id:6,
      image: yourImage6,
      backgroundColor: '#6ADB84',
      textColor: '#003249',
      buttonColor: '#F18934',
      margin: "-125px",
      content: {
        title: "Parku i Germise, është zemra e Prishtines",
        description: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,",
        fullDescription: "Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner,Your trusted partner for high-quality outsourcing services. Whether you're a small business owner looking to. Your trusted partner for high-quality .lity sted partner for high-quality outsourcing services. Whether you're a small business owner."
      }
    }
  ];


  export { data } ;