import React from 'react'
import Activity from '../activity/Activity'

const ActivityPage = () => {
  return (
    <div>
        <Activity />
    </div>
  )
}

export default ActivityPage