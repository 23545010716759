import React from 'react'
import AboutUs from '../about/AboutUs'
import Gallery from '../about/Gallery'

const AboutUsPage = () => {
  return (
    <>
        <AboutUs />
        <Gallery />
    </>
  )
}

export default AboutUsPage